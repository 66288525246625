import React from "react"

import SvgIcon from "../components/SvgIcon"

const IconEdit = () => {
  return (
    <SvgIcon viewBox="0 0 20 20">
      <path d="M7.01 16.084l-4.242.707.707-4.243 3.536 3.536zm5.658-9.9l-7.071 7.071-1.415-1.414 7.072-7.071 1.414 1.414zm2.121 2.121l-7.071 7.072-1.414-1.415 7.07-7.07 1.415 1.413zm1.19-4.466c.42.42.618.75.726 1.103.107.353.107.715 0 1.069s-.305.684-.725 1.104l-.484.483-3.535-3.535.483-.484c.42-.42.75-.619 1.103-.725a1.813 1.813 0 011.07 0c.353.107.682.304 1.102.724l.26.261z" />
    </SvgIcon>
  )
}

export default IconEdit
